import stl from "./CardHeader.module.css";
import DiscountLabel from "../../../NewComponents/Cards/SearchCard/DiscountLabel/DiscountLabel";
import MarkitingLabel from "../../../NewComponents/Cards/SearchCard/MarkitingLabel/MarkitingLabel";
import { isFavoritePost } from "@/utils/auth";
import AddToFavoriteButton from "../../../../UiKit/Buttons/AddToFavorite/AddToFavoriteButton";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { thumbUrl } from "@/utils/imagesHelpers";
import { useTranslation } from "@i18n";
import useIsMobile from "@hooks/useIsMobile";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { useLang } from "@/common/Contexts/LanguageContext";

const CardHeader = ({
  post,
  isEager,
  isCustomDesign,
  hideFavIcon,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  HasImageSlider = false,
  to_link,
  slideOnlyOnArr = false,
}) => {
  const { t } = useTranslation(["web_search"]);
  const isMobile = useIsMobile();
  const lang = useLang();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showAllImages, setShowAllImages] = useState(false);
  const [openGalleryInPost, setOpenGalleryInPost] = useState(false);
  const [isSlideing, setIsSlideing] = useState(false);

  const startX = useRef(0);
  const currentX = useRef(0);
  const isSwiping = useRef(false);

  useEffect(() => {
    if (selectedIndex != 0) {
      setShowAllImages(true);
    }
  }, [selectedIndex]);

  const handleTouchStart = (event) => {
    startX.current = event.touches[0].clientX;
    isSwiping.current = true;
  };

  const handleTouchMove = (event) => {
    if (!isSwiping.current) return;
    currentX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!isSwiping.current) return;

    const deltaX = startX.current - currentX.current;
    if (lang == "ar") {
      if (deltaX < -50) {
        post.images_urls?.length - 1 != selectedIndex && SlideImage("next");
      } else if (deltaX > 50) {
        0 != selectedIndex && SlideImage("prev");
      }
    }
    if (lang == "en") {
      if (deltaX > -50) {
        post.images_urls?.length - 1 != selectedIndex && SlideImage("next");
      } else if (deltaX < 50) {
        0 != selectedIndex && SlideImage("prev");
      }
    }

    isSwiping.current = false;
  };

  const SlideImage = (nextOrPrev) => {
    if (!isSlideing) {
      setIsSlideing(true);
      if (nextOrPrev === "prev") {
        selectedIndex === 0
          ? setSelectedIndex(post?.images_urls?.length - 1)
          : setSelectedIndex(selectedIndex - 1);
      }
      if (nextOrPrev === "next") {
        selectedIndex === post?.images_urls?.length - 1
          ? setSelectedIndex(0)
          : setSelectedIndex(selectedIndex + 1);
      }
      if (post.images_urls?.length == selectedIndex + 1) {
        setOpenGalleryInPost(true);
      }
    }
    setTimeout(() => {
      setIsSlideing(false);
    }, 500);
  };

  TheImage = TheImage
    ? TheImage
    : () => (
        <ProgressiveImage
          alt={post?.title ? post?.title : "car"}
          src={post.image_url}
          loading={isEager ? "eager" : "lazy"}
          fetchpriority={isEager ? "high" : "low"}
          progressiveSmallImage={thumbUrl(
            post?.image_url,
            "online-v1",
            isMobile ? "0x99" : "0x154"
          )}
          width={205}
          height={154}
        />
      );

  return (
    <div className={stl.cardHead}>
      {post?.discount && !isCustomDesign && !hideDiscountLabel ? (
        <DiscountLabel text={post.discount} />
      ) : (
        ""
      )}
      {post.campaign_id &&
      !post.limited_quantity &&
      !isCustomDesign &&
      !hideMarkitingLabel ? (
        <MarkitingLabel
          campaign={{
            id: post.campaign_id,
            txt: post.campaign_txt,
            bg_color: post.campaign_bg_color_hex,
          }}
        />
      ) : (
        ""
      )}
      {post.is_booked ? (
        <div className={stl.reservedCarTag}>
          <span>{t("محجوزة", { ns: "web_search" })}</span>
        </div>
      ) : (
        ""
      )}
      {(post?.images_urls?.length == 1 || !HasImageSlider) &&
      post?.image_url?.length > 0 ? (
        <Link to={to_link}>
          <TheImage />
        </Link>
      ) : (
        <div
          className={stl.carSliderCntainer}
          onTouchStart={!slideOnlyOnArr && handleTouchStart}
          onTouchMove={!slideOnlyOnArr && handleTouchMove}
          onTouchEnd={!slideOnlyOnArr && handleTouchEnd}
        >
          <span
            className={[
              stl.prevArr,
              selectedIndex == 0 ? stl.hideArr : undefined,
            ].join(" ")}
            onClick={(event) => {
              selectedIndex != 0 && SlideImage("prev");
            }}
          ></span>

          <span
            className={[
              stl.nxtArr,
              selectedIndex == post.images_urls?.length - 1
                ? stl.hideArr
                : undefined,
            ].join(" ")}
            onClick={(event) => {
              selectedIndex != post.images_urls?.length - 1 &&
                SlideImage("next");
            }}
          ></span>

          <span className={stl.counter}>
            {selectedIndex + 1}/{post?.images_urls?.length}
          </span>
          <Link
            to={
              to_link +
              (post.images_urls?.length == selectedIndex + 1
                ? "&bigGallView=true"
                : "")
            }
          >
            {post.images_urls?.map((image, idx) => {
              return (
                // (idx == 0 || showAllImages)
                //  && (

                <ProgressiveImage
                  key={idx}
                  className={[
                    stl.singleItem,
                    selectedIndex == idx ? stl.activeItem : undefined,
                    idx === selectedIndex ? stl.activeSlide : undefined,
                    idx === selectedIndex ? "activeSlide" : undefined,
                    idx > selectedIndex ? stl.nextSlide : undefined,
                    idx < selectedIndex ? stl.prevSlide : undefined,
                    selectedIndex === post?.images_urls?.length - 1 && idx === 0
                      ? stl.actAsLst
                      : undefined,
                    idx === post?.images_urls?.length - 1 && selectedIndex === 0
                      ? stl.actAsfirst
                      : undefined,
                  ].join(" ")}
                  src={image}
                  alt={post?.title ? post?.title : "car"}
                  loading={idx == 0 && isEager ? "eager" : "lazy"}
                  fetchpriority={isEager ? "high" : "low"}
                  progressiveSmallImage={thumbUrl(
                    post?.image_url,
                    "online-v1",
                    isMobile ? "0x99" : "0x154"
                  )}
                  width={205}
                  height={154}
                />
                // )
              );
            })}
          </Link>
          {post.images_urls?.length == selectedIndex + 1 && (
            <div className={stl.hoverdData}>
              <span>{t("شاهد جميع الصور", { ns: "web_search" })}</span>
            </div>
          )}
        </div>
      )}
      {!isCustomDesign && !hideFavIcon && (
        <AddToFavoriteButton
          isFavorite={isFavoritePost(+post.uniqueId)}
          postId={+post.uniqueId}
          authCarCondition={post.condition_id}
          className={stl.FavoriteButton}
          page="search"
          referral_location={"listing_page"}
          g4_data={post.g4_data_layer}
        />
      )}
    </div>
  );
};

export default CardHeader;
